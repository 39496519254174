module.exports = [{
      plugin: require('/var/lib/jenkins/workspace/aga_store/node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-138054696-1","head":false},
    },{
      plugin: require('/var/lib/jenkins/workspace/aga_store/node_modules/gatsby-plugin-offline/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('/var/lib/jenkins/workspace/aga_store/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
